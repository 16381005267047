/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { darken, complement } from 'polished';
import styled from 'styled-components';
import Spinner from './Spinner';


/**
 *
 *
 *
 */
ButtonWait.defaultProps = {
  $bg: '#FFF',
  $br: 'rgb(208, 215, 222)',
  $tt: 800,
  onClick: () => {},
  $style: {},
};


/**
 *
 *
 *
 */
export default function ButtonWait(props) {

  const [ isWait, setIsWait ] = React.useState(false);

  return (
    <S.Container $bg={props.$bg} $br={props.$br} onClick={onClick} style={props.$style}>
      {onInnerBodyComponent()}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  async function onClick() {
    if (isWait) return;
    setIsWait(true);
    setTimeout(async () => {
      await props.onClick();
      setIsWait(false);
    }, props.$tt);
  }

  /**
   *
   *
   *
   */
  function onInnerBodyComponent() {
    const $currBg = R.tryCatch(() => complement(props.$bg), () => complement(props.$br));
    if (isWait) return <Spinner $bg={$currBg} />;
    return props?.children;
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div.attrs({ className: 'button_wait' })`
  width: 100%;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${p => p.$bg};
  border: 1px solid ${p => p.$br ?? p.$bg};
  &:hover { cursor: pointer; background: ${p => R.tryCatch(() => darken(0.1, p.$bg), () => darken(0.1, '#fff'))}; }
`;

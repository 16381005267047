/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import Input from '../../_components/Input';
import Table from '../../_components/Table';
import ButtonDanger from '../../_components/Button.Danger';
import * as I from '../../_components/Icons';
import T from '../../_components/Typography';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function SpeciesItem(props) {

  const { actions } = React.useContext(Store.Context);

  return (
    <S.Container>
      <S.ColumnContainer>
        <S.InnerContainer>
          <Input $hasCopy={true} readOnly={true} value={props?.id} />
          <S.CreateContainer onClick={() => actions.onAdminCreateBreed({ specie_id: props?.id })}>
            <I.Plus style={{ transform: 'scale(1.2)' }} stroke={'#FD8C73'} />
          </S.CreateContainer>
        </S.InnerContainer>
        <Input {...onSpecieNameProps()} />
      </S.ColumnContainer>
      <S.ColumnContainer $s={8}>
        <Table {...onTableProps()} />
      </S.ColumnContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onSpecieNameProps() {
    return {
      placeholder: 'Specie Name',
      value: props?.name,
      onSave: async name => await actions.onAdminUpdateSpecie({ id: props?.id, name })
    };
  }

  /**
   *
   *
   *
   */
  function onTableProps() {
    return {
      subFunc: onSubFunc,
      $theme: '#ebfbee',
      data: props?.breeds?.map(b => ({
        id: b.id,
        name: b.name,
      })),
    };
  }

  /**
   *
   *
   *
   */
  function onSubFunc(row) {
    return (
      <S.SubTableContainer $isLast={R.equals(row.id, R.last(props?.breeds).id)}>
        <S.Container>
          <S.ColumnContainer $s={6}>
            <Input {...onBreedNameProps(row)} />
          </S.ColumnContainer>
          <S.ColumnContainer $s={6}>
            <ButtonDanger onClick={() => actions.onAdminDeleteBreed({ id: row.id })}>
              <T.IX5 color={'white'}>Delete</T.IX5>
            </ButtonDanger>
          </S.ColumnContainer>
        </S.Container>
      </S.SubTableContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onBreedNameProps(row) {
    return {
      placeholder: 'Breed Name',
      value: row.name,
      onSave: async name => await actions.onAdminUpdateBreed({ id: row.id, name })
    };
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
`;

S.ColumnContainer = styled.div`
  height: ${p => p.$h ?? '100%'};
  grid-column: span ${p => p.$s ?? 4};
  display: ${p => p.$d ?? 'grid'};
  grid-row-gap: 10px;
  align-content: start;
`;

S.SubTableContainer = styled.div`
  width: 100%;
  padding: 20px;
  border-top: 1px solid #D0D7DE;
  ${p => !p.$isLast && `border-bottom: 1px solid #D0D7DE;`}
`;

S.CreateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid rgb(208, 215, 222);
  min-width: 50px;
  height: 100%;
  margin-left: 10px;
  &:hover { cursor: pointer; background: rgba(253, 140, 115, 0.1); }
`;

S.InnerContainer = styled.div`
  display: flex;
  align-items: center;
`;
/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import Input from '../../_components/Input';
import ButtonWait from '../../_components/Button.Wait';
import MonacoEditor from '../../_components/MonacoEditor';
import Selector from '../../_components/Selector';
import T from '../../_components/Typography';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function SettingsItem(props) {

  const { actions } = React.useContext(Store.Context);
  const [ currInfo, setCurrInfo ] = React.useState();

  return (
    <S.Container>
      <S.ColumnContainer $s={2} $d={'block'}>
        <ButtonWait $bg={'#4CAF50'} onClick={onFetchRedis}>
          <T.IL5 $c={'#FFF'}>Fetch Redis</T.IL5>
        </ButtonWait>
      </S.ColumnContainer>
      <S.ColumnContainer $s={5} $d={'block'}>
        <MonacoEditor {...onSubProps()} />
      </S.ColumnContainer>
      <S.ColumnContainer $s={5} $d={'block'}>
        <MonacoEditor {...onRedisProps()} />
      </S.ColumnContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  async function onFetchRedis() {
    const key = `_prv:${props.hash}`;
    const data = await actions.onAuthGetRedis({ key });
    return setCurrInfo(data);
  }

  /**
   *
   *
   *
   */
  function onSubProps() {
    return {
      key: JSON.stringify(props),
      onSave: (txt) => console.log(`txt`, txt),
      value: JSON.stringify(props, null, 2),
      language: 'json',
    };
  }

  /**
   *
   *
   *
   */
  function onRedisProps() {
    return {
      key: JSON.stringify(currInfo),
      onSave: (txt) => console.log(`txt`, txt),
      value: JSON.stringify(currInfo, null, 2),
      language: 'json',
    };
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
`;

S.ColumnContainer = styled.div`
  height: ${p => p.$h ?? '100%'};
  grid-column: span ${p => p.$s ?? 4};
  display: ${p => p.$d ?? 'grid'};
  grid-row-gap: 10px;
  align-content: start;
`;

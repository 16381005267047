/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import GitHubLikeEditor from '../../_components/GitHubLikeEditor';
import ButtonWait from '../../_components/Button.Wait';
import T from '../../_components/Typography';
import Input from '../../_components/Input';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function StaffOverview() {

  const { state, actions } = React.useContext(Store.Context);
  const currItem = onActiveItemInfo();
  const id = currItem?.id;

  return (
    <S.Container>
      <S.TopContainer>
        <S.InnerContainer>
          <S.RowContainer $c={'1fr 5fr 5fr'}>
            <Input value={currItem?.id} $hasCopy={true} readOnly={true} />
            <Input {...onFirstNameProps()} />
            <Input {...onLastNameProps()} />
          </S.RowContainer>
          <S.RowContainer $c={'5fr 5fr 5fr'}>
            <Input {...onPhoneProps()} />
            <Input {...onEmailProps()} />
            <Input {...onPasswordProps()} />
          </S.RowContainer>
        </S.InnerContainer>
        <S.AvatarContainer>
          <S.AvatarPhoto />
          <ButtonWait {...onGenProps()}>
            <T.IL6 color="white">Generate</T.IL6>
          </ButtonWait>
        </S.AvatarContainer>
      </S.TopContainer>
      <S.BottomContainer />
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onActiveItemInfo() {
    if (!state?.currActiveStaff?.id) return;
    const isSame = e => Number(e.id) === state?.currActiveStaff?.id;
    return state?.staff?.find(isSame);
  }

  /**
   *
   *
   *
   */
  function onGenProps() {
    return {
      $bg: '#c77dff',
      $br: '#c77dff',
      $tt: 800,
      onClick: onGenerate,
      style: { minHeight: 40, maxHeight: 40 },
    };
  }

  /**
   *
   *
   *
   */
  async function onGenerate() {
    // await actions.onGenerateAvatar({ id });
  }

  /**
   *
   *
   *
   */
  function onFirstNameProps() {
    return {
      placeholder: 'First name',
      value: currItem?.first_name,
      onSave: async (first_name) => await actions.onAdminUpdateStaff({ id, first_name }),
    };
  }

  /**
   *
   *
   *
   */
  function onLastNameProps() {
    return {
      placeholder: 'Last name',
      value: currItem?.last_name,
      onSave: async (last_name) => await actions.onAdminUpdateStaff({ id, last_name }),
    };
  }

  /**
   *
   *
   *
   */
  function onPhoneProps() {
    return {
      placeholder: 'Phone',
      value: currItem?.phone,
      onSave: async (phone) => await actions.onAdminUpdateStaff({ id, phone }),
    };
  }

  /**
   *
   *
   *
   */
  function onEmailProps() {
    return {
      placeholder: 'Email',
      value: currItem?.email,
      onSave: async (email) => await actions.onAdminUpdateStaff({ id, email }),
    };
  }

  /**
   *
   *
   *
   */
  function onPasswordProps() {
    return {
      placeholder: 'Password',
      value: currItem?.password,
      onSave: async (password) => await actions.onAdminUpdateStaff({ id, password }),
    };
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  height: 100%;
`;

S.TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

S.InnerContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 1fr;
`;

S.AvatarContainer = styled.div`
  min-width: 160px;
  margin-left: 10px;
`;

S.AvatarPhoto = styled.div`
  margin-bottom: 10px;
  background-color: rgb(246, 248, 250);
  border: 1px solid rgb(208, 215, 222);
  border-radius: 4px;
  min-height: 140px;
  max-height: 140px;
  background-image: url('https://github-production-user-asset-6210df.s3.amazonaws.com/7887496/359227336-d3a11dd6-9043-4309-9816-fe7c78859753.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVCODYLSA53PQK4ZA%2F20240819%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240819T195334Z&X-Amz-Expires=300&X-Amz-Signature=d946cb7eb76a9e8a5ee6cb500a1a122791a9db9db1d1a3a07107d51abd5f8727&X-Amz-SignedHeaders=host&actor_id=7887496&key_id=0&repo_id=844115774');
  background-image: url('https://github-production-user-asset-6210df.s3.amazonaws.com/7887496/359229051-abb91054-d03a-43e1-86f7-4caca4aefc79.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVCODYLSA53PQK4ZA%2F20240819%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240819T195941Z&X-Amz-Expires=300&X-Amz-Signature=cf48273cf0bb296c9c83f9304eb03ec01d476c5b2464d74e6fd4353b2be647e0&X-Amz-SignedHeaders=host&actor_id=7887496&key_id=0&repo_id=844115774');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

S.RowContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${p => p.$c};
`;

S.BottomContainer = styled.div`
  margin-top: 10px;
  height: calc(100% - 200px);
`;

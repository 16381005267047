/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';
import T from './Typography';
import * as I from './Icons';


/**
 *
 *
 *
 */
MonacoEditor.defaultProps = {
  width: '100%',
  height: '100%',
  value: null,
  defaultValue: '',
  language: 'markdown',
  lineNumbers: 'on',
  theme: null,
  options: {},
  overrideServices: {},
  onEditorMount: () => {},
  onChange: () => {},
  className: null,
  saveOnKeyStroke: false,
  readOnly: false,
  info: undefined,
  autoFocus: false,
};


/**
 *
 *
 *
 */
window.monaco.editor.defineTheme('light', {
  base: 'vs',
  inherit: true,
  rules: [],
  colors: { 'editor.background': '#F6F8FA' }
});


/**
 *
 *
 *
 */
window.monaco.editor.defineTheme('dark', {
  base: 'vs-dark',
  inherit: true,
  rules: [],
  colors: { 'editor.background': '#222426' }
});


/**
 *
 *
 *
 */
window.monaco.editor.setTheme('light');


/**
 *
 *
 *
 */
window.monaco.languages.registerCompletionItemProvider('javascript', {
  provideCompletionItems: function() {
    return {
      suggestions: [
        {
          label: 'logs',
          kind: window.monaco.languages.CompletionItemKind.Snippet,
          insertText: 'console.log(`${1}`, ${1});',
          insertTextRules: window.monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Console log snippet'
        },
        {
          label: 'cc',
          kind: window.monaco.languages.CompletionItemKind.Snippet,
          insertText: '/**\n *\n *\n *\n */',
          insertTextRules: window.monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Comment block'
        }
      ]
    };
  }
});


/**
 *
 *
 *
 */
window.monaco.languages.registerCompletionItemProvider('python', {
  provideCompletionItems: function () {
    return {
      suggestions: [
        {
          label: 'logs',
          kind: window.monaco.languages.CompletionItemKind.Snippet,
          insertText: 'print(`${1}`, ${1});',
          insertTextRules: window.monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Print snippet'
        },
        {
          label: 'cc',
          kind: window.monaco.languages.CompletionItemKind.Snippet,
          insertText: '#\n#\n#\n#\n${1}',
          insertTextRules: window.monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Comment block'
        }
      ]
    };
  }
});


/**
 *
 *
 *
 */
export default function MonacoEditor(props) {

  const containerElm = React.useRef(null);
  const instance = React.useRef(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [value, setValue] = React.useState(props.value);
  React.useEffect(onStartEditorInstance, [props.id]);
  const isSpurious = props.value !== value;

  return (
    <S.Container $height={props.height}>
      {props.info && onGetInfoComponent()}
      {isSpurious && !isSaving && (<S.OrangePunto />)}
      {isSaving && onGetLoading()}
      <S.MonacoContainer
        ref={containerElm}
        $height={props.height}
        $width={props.width}
      />
      {props.isDrag && onGetDragComponent()}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onStartEditorInstance() {

    if (!containerElm.current) return;

    const options = {
      value: props.value,
      language: onLanguage(),
      automaticLayout: true,
      minimap: { enabled: false },
      fontSize: props.font ?? 12,
      tabSize: 2,
      lineNumbers: props.lineNumbers,
      renderLineHighlight: 'none',
      scrollbar: { vertical: 'hidden' },
      guides: { indentation: props.guides ?? true },
      overviewRulerBorder: false,
      hideCursorInOverviewRuler: true,
      renderWhitespace: props.renderWhitespace ?? 'all',
      lineDecorationsWidth: 0,
      lineNumbersMinChars: 3,
      padding: { top: 5 },
      readOnly: props.readOnly,
      scrollBeyondLastLine: false,
      rulers: props.rulers,
      wordWrap: 'wordWrapColumn',
      wordWrapColumn: 60,
    };

    instance.current = window.monaco.editor.create(containerElm.current, options);

    instance.current.onDidChangeModelContent(function() {
      const currValue = instance.current.getValue();
      setValue(currValue);
      const doSave = props.saveOnKeyStroke && props.onSave;
      if (doSave) onInnerSave(currValue);
      props.onChange(currValue);
    });

    const onSaveAction = instance.current.addAction({
      id: 'save-action-item',
      label: 'Save',
      precondition: null,
      keybindings: [window.monaco.KeyMod.CtrlCmd | window.monaco.KeyCode.KeyS],
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 1.5,
      run: ed => props.onSave && onInnerSave(ed.getValue()),
    });

    const onSubmitAction = instance.current.addAction({
      id: 'submit-action-item',
      label: 'Run Query',
      precondition: null,
      keybindings: [window.monaco.KeyMod.CtrlCmd | window.monaco.KeyCode.Enter],
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 1.5,
      run: ed => props.onSubmit && props.onSubmit(ed.getValue()),
    });

    const onSocratesAction = instance.current.addAction({
      id: 'ask-action-item',
      label: 'Ask Socrates',
      precondition: null,
      keybindings: [window.monaco.KeyMod.CtrlCmd | window.monaco.KeyCode.KeyI],
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 1.5,
      run: onAskSocratesInstance,
    });

    props.onEditorMount(instance.current);
    const currValue = instance.current.getValue();
    props.onChange(currValue);
    if (props.autoFocus) instance.current.focus();

    return () => {
      instance.current.dispose();
      onSaveAction.dispose();
      onSubmitAction.dispose();
      onSocratesAction.dispose();
    };
  }

  /**
   *
   *
   *
   */
  function onAskSocratesInstance(currEd) {
    console.log(`onAskSocrates:currEd`, currEd);
    const currWindow = `<div style="position: absolute; top: 0; left: 0; width: 100px; height: 100px; background: red; z-index: 1000;"></div>`;
    document.body.insertAdjacentHTML('beforeend', currWindow);
    const selectedText = currEd.getModel().getValueInRange(currEd.getSelection());
    console.log('Selected Text:', selectedText);
    console.log(`ed.getValue()`, currEd.getValue());
  }

  /**
   *
   *
   *
   */
  function onLanguage() {
    if (props.language === 'sqlite3') return 'sql';
    return props.language;
  }

  /**
   *
   *
   *
   */
  async function onInnerSave(value) {
    setIsSaving(true);
    await props.onSave(value);
    setIsSaving(false);
  }

  /**
   *
   *
   *
   */
  function onGetInfoComponent() {
    return (
      <S.AbsInfoContainer>
        <S.InfoContainer>
          <I.Info />
          <S.InfoInnerText>
            {props.info.map(txt => (
              <T.ISX4 key={txt}>{txt}</T.ISX4>
            ))}
          </S.InfoInnerText>
        </S.InfoContainer>
      </S.AbsInfoContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onGetLoading() {
    return (
      <S.AbsContainer>
        <Spinner $scale={0.25} $bg={'#FD8C73'} />
      </S.AbsContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onGetDragComponent() {
    return (
      <S.DragContent>
        <T.IM4>
          Attach files by dragging & dropping,
          selecting or pasting them.
        </T.IM4>
      </S.DragContent>
    );
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  position: relative;
  height: ${p => p.$height ?? '100%'};
`;

S.MonacoContainer = styled.div`
  width: ${p => p.$width ?? '100%'};
  height: ${p => p.$height ?? '100%'};
  background-color: #F6F8FA;
  border: 1px solid #D0D7DE;
  border-radius: 4px;
  overflow: auto;
  &.custom-editor-padding .view-lines { padding-top: 5px; }
  .autocomplete-suggestion { color: grey; }
`;

S.OrangePunto = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 35px;
  right: 9px;
  background-color: rgb(253, 140, 115);
  border-radius: 50%;
  z-index: 2;
`;

S.AbsContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 1px;
  right: -25px;
`;

S.DragContent = styled.div`
  position: absolute;
  display: flex;
  border-top: 1px dashed #D0D7DE;
  border-bottom: 1px solid #D0D7DE;
  border-left: 1px solid #D0D7DE;
  border-right: 1px solid #D0D7DE;
  border-radius: 0px 0px 4px 4px;
  background-color: #F6F8FA;
  min-height: 30px;
  padding: 6px;
  width: 100%;
  bottom: 0px;
`;

S.AbsInfoContainer = styled.div`
  z-index: 5;
  position: absolute;
  top: 9px;
  right: 6px;
`;

S.InfoInnerText = styled.div`
  position: absolute;
  display: none;
  padding: 4px;
  top: 20px;
  right: 0px;
`;

S.InfoContainer = styled.div`
  position: relative;
  cursor: pointer;
  &:hover ${S.InfoInnerText} {
    display: flex;
    flex-direction: column;
    width: 140px;
    border-radius: 4px;
    background: white;
    border: 1px solid #D0D7DE;
  }
`;
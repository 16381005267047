/**
 *
 *
 *
 */
import React from 'react';
import { produce } from 'immer';
import * as u from '../utils';
import * as F from './frgs';


/**
 *
 *
 *
 */
export const init = {
  /**
   *
   *
   *
   */
  me: null,
  /**
   * Determine what is highlited in the
   * folder navigation on the left e.g.
   *
   * { id: 21, type: 'account' }
   */
  currActiveAccount: undefined,
  /**
   * Determine what is highlited in the
   * folder navigation on the left e.g.
   *
   * { id: 21, type: 'staff' }
   */
  currActiveStaff: undefined,
  /**
   *
   *
   *
   */
  accounts: [],
  /**
   *
   *
   *
   */
  staff: [],
  /**
   *
   *
   *
   */
  submissions: [],
  /**
   *
   *
   *
   */
  users: [],
  /**
   *
   *
   *
   */
  species: [],
};


/**
 *
 *
 *
 */
export const reducer = produce((state = init, action) => {
  switch (action.type) {
    case 'AUTH_GET_ME': {
      state.me = action.payload;
      return state;
    }
    case 'SET_ACTIVE_ACCOUNT': {
      state.currActiveAccount = action.payload;
      return state;
    }
    case 'SET_ACTIVE_STAFF': {
      state.currActiveStaff = action.payload;
      return state;
    }
    case 'ADMIN_GET_ACCOUNTS': {
      state.accounts = action.payload;
      return state;
    }
    case 'ADMIN_CREATE_ACCOUNT': {
      state.accounts.push(action.payload);
      return state;
    }
    case 'ADMIN_UPDATE_ACCOUNT': {
      const isSame = elm => Number(elm.id) === Number(action.payload.id);
      const idxC = state.accounts.findIndex(isSame);
      const prev = state.accounts[idxC];
      state.accounts[idxC] = { ...prev, ...action.payload };
      return state;
    }
    case 'ADMIN_GET_SUBMISSIONS': {
      state.submissions = action.payload;
      return state;
    }
    case 'ADMIN_GET_STAFF': {
      state.staff = action.payload;
      return state;
    }
    case 'ADMIN_CREATE_STAFF': {
      state.staff.push(action.payload);
      return state;
    }
    case 'ADMIN_UPDATE_STAFF': {
      const isSame = elm => Number(elm.id) === Number(action.payload.id);
      const idxC = state.staff.findIndex(isSame);
      const prev = state.staff[idxC];
      state.staff[idxC] = { ...prev, ...action.payload };
      return state;
    }
    case 'ADMIN_CREATE_USER': {
      const isSameAcc = elm => Number(elm.id) === Number(action.payload.account_id);
      const idxA = state.accounts.findIndex(isSameAcc);
      if (!state.accounts[idxA].users) state.accounts[idxA].users = [];
      state.accounts[idxA].users.push(action.payload);
      return state;
    }
    case 'ADMIN_UPDATE_USER': {
      const isSameAcc = elm => Number(elm.id) === Number(action.payload.account_id);
      const isSameUsr = elm => Number(elm.id) === Number(action.payload.id);
      const idxA = state.accounts.findIndex(isSameAcc);
      const idxU = state.accounts[idxA].users.findIndex(isSameUsr);
      const prev = state.accounts[idxA].users[idxU];
      state.accounts[idxA].users[idxU] = { ...prev, ...action.payload };
      return state;
    }
    case 'ADMIN_GET_USERS': {
      state.users = action.payload;
      return state;
    }
    case 'ADMIN_GET_SPECIES': {
      state.species = action.payload;
      return state;
    }
    case 'ADMIN_CREATE_SPECIE': {
      state.species.push(action.payload);
      return state;
    }
    case 'ADMIN_UPDATE_SPECIE': {
      const isSame = elm => Number(elm.id) === Number(action.payload.id);
      const idxC = state.species.findIndex(isSame);
      const prev = state.species[idxC];
      state.species[idxC] = { ...prev, ...action.payload };
      return state;
    }
    case 'ADMIN_CREATE_BREED': {
      const isSameSpecie = elm => Number(elm.id) === Number(action.payload.specie_id);
      const idxS = state.species.findIndex(isSameSpecie);
      if (!state.species[idxS].breeds) state.species[idxS].breeds = [];
      state.species[idxS].breeds.push(action.payload);
      return state;
    }
    case 'ADMIN_UPDATE_BREED': {
      const isSameSpecie = elm => Number(elm.id) === Number(action.payload.specie_id);
      const isSameBreed = elm => Number(elm.id) === Number(action.payload.id);
      const idxS = state.species.findIndex(isSameSpecie);
      const idxB = state.species[idxS].breeds.findIndex(isSameBreed);
      const prev = state.species[idxS].breeds[idxB];
      state.species[idxS].breeds[idxB] = { ...prev, ...action.payload };
      return state;
    }
    case 'ADMIN_CREATE_DEEP_LINK': {
      const isSameAcc = elm => Number(elm.id) === Number(action.payload.account_id);
      const isSameUsr = elm => Number(elm.id) === Number(action.payload.user_id);
      const idxA = state.accounts.findIndex(isSameAcc);
      const idxU = state.accounts[idxA].users.findIndex(isSameUsr); 
      if (!state.accounts[idxA].users[idxU].deep_links) state.accounts[idxA].users[idxU].deep_links = [];
      state.accounts[idxA].users[idxU].deep_links.push(action.payload);
      return state;
    }
    default:
      return state;
  }
});


/**
 *
 *
 *
 */
export const getActions = (state, dispatch) => {

  /**
   *
   *
   *
   */
  const inner = {};

  /**
   *
   *
   *
   */
  inner.onAuthGetMe = async function onAuthGetMe() {
    const [data, err] = await u.fetcher(F.AUTH_GET_ME);
    if (err) return console.log(`onAppGetMe:err`, err);
    dispatch({ type: 'AUTH_GET_ME', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminGetAccounts = async function onAdminGetAccounts() {
    const [data, err] = await u.fetcher(F.ADMIN_GET_ACCOUNTS);
    if (err) return console.log(`onAdminGetAccounts:err`, err);
    dispatch({ type: 'ADMIN_GET_ACCOUNTS', payload: data });
  }

  /**
   *
   *
   *
   */
  inner.onAdminCreateAccount = async function onAdminCreateAccount() {
    const [data, err] = await u.fetcher(F.ADMIN_CREATE_ACCOUNT);
    if (err) return console.log(`onAdminCreateAccount:err`, err);
    dispatch({ type: 'ADMIN_CREATE_ACCOUNT', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminUpdateAccount = async function onAdminUpdateAccount(params) {
    const [data, err] = await u.fetcher(F.ADMIN_UPDATE_ACCOUNT, params);
    if (err) return console.log(`onAdminUpdateAccount:err`, err);
    dispatch({ type: 'ADMIN_UPDATE_ACCOUNT', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminGetSubmissions = async function onAdminGetSubmissions() {
    const [data, err] = await u.fetcher(F.ADMIN_GET_SUBMISSIONS);
    if (err) return console.log(`onAdminGetSubmissions:err`, err);
    dispatch({ type: 'ADMIN_GET_SUBMISSIONS', payload: data });
  };


  /**
   *
   *
   *
   */
  inner.onAdminCreateUser = async function onAdminCreateUser() {
    const params = { account_id: state.currActiveAccount.id };
    const [data, err] = await u.fetcher(F.ADMIN_CREATE_USER, params);
    if (err) return console.log(`onAdminCreateUser:err`, err);
    dispatch({ type: 'ADMIN_CREATE_USER', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminUpdateUser = async function onAdminUpdateUser(params) {
    const [data, err] = await u.fetcher(F.ADMIN_UPDATE_USER, params);
    if (err) return console.log(`onAdminUpdateUser:err`, err);
    dispatch({ type: 'ADMIN_UPDATE_USER', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminGetStaff = async function onAdminGetStaff() {
    const [data, err] = await u.fetcher(F.ADMIN_GET_STAFF);
    if (err) return console.log(`onAdminGetStaff:err`, err);
    dispatch({ type: 'ADMIN_GET_STAFF', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminCreateStaff = async function onAdminCreateStaff() {
    const [data, err] = await u.fetcher(F.ADMIN_CREATE_STAFF);
    if (err) return console.log(`onAdminCreateStaff:err`, err);
    dispatch({ type: 'ADMIN_CREATE_STAFF', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminUpdateStaff = async function onAdminUpdateStaff(params) {
    const [data, err] = await u.fetcher(F.ADMIN_UPDATE_STAFF, params);
    if (err) return console.log(`onAdminUpdateStaff:err`, err);
    dispatch({ type: 'ADMIN_UPDATE_STAFF', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminGetUsers = async function onAdminGetUsers() {
    const [data, err] = await u.fetcher(F.ADMIN_GET_USERS);
    if (err) return console.log(`onAdminGetUsers:err`, err);
    dispatch({ type: 'ADMIN_GET_USERS', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAuthGetRedis = async function onAuthGetRedis(params) {
    const [data, err] = await u.fetcher(F.AUTH_GET_REDIS, params);
    if (err) return console.log(`onAuthGetRedis:err`, err);
    return data;
  };

  /**
   *
   *
   *
   */
  inner.onAuthSetRedis = async function onAuthSetRedis(params) {
    const [data, err] = await u.fetcher(F.AUTH_SET_REDIS, params);
    if (err) return console.log(`onAuthSetRedis:err`, err);
    return data;
  };

  /**
   *
   *
   *
   */
  inner.onAdminGetSpecies = async function onAdminGetSpecies() {
    const [data, err] = await u.fetcher(F.ADMIN_GET_SPECIES);
    if (err) return console.log(`onAdminGetSpecies:err`, err);
    dispatch({ type: 'ADMIN_GET_SPECIES', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminCreateSpecie = async function onAdminCreateSpecie() {
    const [data, err] = await u.fetcher(F.ADMIN_CREATE_SPECIE);
    if (err) return console.log(`onAdminCreateSpecie:err`, err);
    dispatch({ type: 'ADMIN_CREATE_SPECIE', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminUpdateSpecie = async function onAdminUpdateSpecie(params) {
    const [data, err] = await u.fetcher(F.ADMIN_UPDATE_SPECIE, params);
    if (err) return console.log(`onAdminUpdateSpecie:err`, err);
    dispatch({ type: 'ADMIN_UPDATE_SPECIE', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminCreateBreed = async function onAdminCreateBreed(params) {
    const [data, err] = await u.fetcher(F.ADMIN_CREATE_BREED, params);
    if (err) return console.log(`onAdminCreateBreed:err`, err);
    dispatch({ type: 'ADMIN_CREATE_BREED', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminUpdateBreed = async function onAdminUpdateBreed(params) {
    const [data, err] = await u.fetcher(F.ADMIN_UPDATE_BREED, params);
    if (err) return console.log(`onAdminUpdateBreed:err`, err);
    dispatch({ type: 'ADMIN_UPDATE_BREED', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAdminCreateDeepLink = async function onAdminCreateDeepLink(params) {
    const [data, err] = await u.fetcher(F.ADMIN_CREATE_DEEP_LINK, params);
    if (err) return console.log(`onAdminCreateDeepLink:err`, err);
    dispatch({ type: 'ADMIN_CREATE_DEEP_LINK', payload: { ...params, ...data } });
  };

  /**
   *
   *
   *
   */
  return inner;
};


/**
 *
 *
 *
 */
export const Context = React.createContext({});


/**
 *
 *
 *
 */
export const Provider = (props) => {

  const currReducer = props.reducer ?? reducer;
  const currState = props.state ?? init;
  const [state, dispatch] = React.useReducer(currReducer, currState);
  const actions = getActions(state, dispatch);
  React.useState(() => { onIniStart(); }, []);

  return (
    <Context.Provider value={{ state, dispatch, actions }}>
      {props.children}
    </Context.Provider>
  );

  /**
   *
   *
   *
   */
  async function onIniStart() {
    Promise.all([
      actions.onAuthGetMe(),
      actions.onAdminGetSubmissions(),
      actions.onAdminGetAccounts(),
      actions.onAdminGetStaff(),
      actions.onAdminGetSpecies(),
    ]);
  }
};

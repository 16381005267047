/**
 *
 *
 *
 */
import React from 'react';
import* as R from 'ramda';
import styled from 'styled-components';
import Table from '../../_components/Table';
import OmniSearch from '../../_components/Omni.Search';
import SpeciesItem from '../components/Species.Item';
import * as I from '../../_components/Icons';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function Species() {

  const { state, actions } = React.useContext(Store.Context);
  // const currItem = onActiveItemInfo();

  return (
    <S.Container>
      <S.HeaderContainer>
        <OmniSearch />
        <S.CreateContainer onClick={actions.onAdminCreateSpecie}>
          <I.Plus style={{ transform: 'scale(1.2)' }} stroke={'#FD8C73'} />
        </S.CreateContainer>
      </S.HeaderContainer>
      <Table {...onTableProps()} />
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onTableProps() {
    return {
      subFunc: onSubFunc,
      data: state?.species?.map(s => ({
        id: s.id,
        name: s.name,
        breeds: s.breeds?.length ?? '-',
      })),
    };
  }

  /**
   *
   *
   *
   */
  function onSubFunc(row) {
    const isLast = R.equals(row.id, R.last(state?.species).id);
    const onSame = e => Number(e.id) === Number(row.id);
    const currSpecie = state?.species?.find(onSame);
    return (
      <S.SubTableContainer $isLast={isLast}>
        <SpeciesItem {...currSpecie} />
      </S.SubTableContainer>
    );
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  height: 100%;
`;

S.HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  width: 100%;
  margin-bottom: 10px;
`;

S.CreateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid rgb(208, 215, 222);
  min-width: 50px;
  height: 100%;
  margin-left: 10px;
  &:hover { cursor: pointer; background: rgba(253, 140, 115, 0.1); }
`;

S.SubTableContainer = styled.div`
  width: 100%;
  padding: 20px;
  border-top: 1px solid #D0D7DE;
  ${p => !p.$isLast && `border-bottom: 1px solid #D0D7DE;`}
`;
/**
 *
 *
 *
 */
import React from 'react';
import * as Router from 'react-router-dom';
import styled from 'styled-components';
import { GlobeStyle } from '../_components/Style';
import Analytics from '../_components/Analytics';
import NotFound from '../_components/NotFound';
import Header from '../_components/Header';
import Home from './pages/Home';
import * as Store from './store';


/**
 *
 *
 *
 */
export default function Admin() {

  return (
    <Store.Provider>
      <Router.BrowserRouter basename="/">
        <GlobeStyle />
        <Header />
        <Analytics />
        <S.PageContainer>
          <Router.Routes>
            <Router.Route path="*"            element={<Home />} />
          </Router.Routes>
        </S.PageContainer>
      </Router.BrowserRouter>
    </Store.Provider>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1200px;
  padding-top: 60px;
  padding-bottom: 10px;
  margin: auto;
  height: 100vh;
  @media (max-width: 600px) {
    padding-top: 50px;
  }
`;

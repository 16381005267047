/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';


/**
 *
 *
 *
 */
export default function Submission() {

  return (
    <S.Container>
    </S.Container>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  padding-top: 10px;
  height: 100%;
`;

/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as u from '../utils';
import T from './Typography';
import * as I from './Icons';


/**
 *
 *
 *
 */
ListaRow.defaultProps = {
  title: 'Default',
  subtitle: 'Default',
  // onSelect: () => console.log('onSelect'),
  // onDelete: () => console.log('onDelete'),
  // onUpdate: () => console.log('onUpdate'),
  showBackColorOnActive: true,
  status: 'SUCCESS',
};


/**
 *
 *
 *
 */
export default function ListaRow(props) {

  const currRef = React.useRef(null);
  const optsRef = React.useRef(null);
  const [isPen, setIsPen] = React.useState(false);
  const [isBin, setIsBin] = React.useState(false);
  const [newTitle, setNewTitle] = React.useState(props.title);
  React.useEffect(() => { isPen && currRef.current.focus(); }, [isPen]);
  const hasOpts = (!!props.onUpdate || !!props.onDelete);
  const $isOpen = (props.$isActive || isBin || isPen) && hasOpts;
  const $isPlus = props.onPlus && !$isOpen;
  const $optsWidth = onOptsWidth();

  return (
    <S.Container {...getSubProps()}>
      <S.Status>
        {getStatusIcon()}
      </S.Status>
      <S.TextContainer>
        {isPen ? (
          <S.Input
            value={newTitle}
            onChange={onChange}
            onKeyDown={onKeyDown}
            ref={currRef}
          />
        ) : (
          <T.IM5>{props.title}</T.IM5>
        )}
        <S.ISX5>{props.subtitle}&nbsp;</S.ISX5>
      </S.TextContainer>
      {!(props.$isActive || isBin || isPen) && hasOpts && (
        <S.Time>
          <S.ISX5>
            {props.other ?? u.onTimeAgo(props.time)}
          </S.ISX5>
        </S.Time>
      )}
      {props.onPlus && !$isOpen && (
        <S.HoverContainer>
          <S.IconContainer onClick={onClickPlus}>
            <I.Plus style={{ transform: 'scale(1.5)' }} />
          </S.IconContainer>
        </S.HoverContainer>
      )}
      {$isOpen && (
        <S.Options $optsWidth={$optsWidth}>
          {(isPen || isBin) ? (
            <React.Fragment>
              <S.IconContainer onClick={onClickConfirm}>
                <I.Tick style={{ transform: 'scale(1.3)' }} />
              </S.IconContainer>
              <S.Space $w={8} />
              <S.IconContainer onClick={onClickDismiss}>
                <I.Close style={{ transform: 'scale(1.3)' }} />
              </S.IconContainer>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {props.onPlus && (
                <React.Fragment>
                  <S.IconContainer onClick={onClickPlus}>
                    <I.Plus style={{ transform: 'scale(1.5)' }} />
                  </S.IconContainer>
                  <S.Space $w={8} />
                </React.Fragment>
              )}
              {props.onUpdate && (
                <S.IconContainer onClick={onClickPen}>
                  <I.Pen style={{ transform: 'scale(1.2)' }} />
                </S.IconContainer>
              )}
              <S.Space $w={8} />
              {props.onDelete && (
                <S.IconContainer onClick={onClickBin}>
                  <I.Bin style={{ transform: 'scale(1.1)' }} />
                </S.IconContainer>
              )}
            </React.Fragment>
          )}
        </S.Options>
      )}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  async function onClickPlus(evt) {
    evt.stopPropagation();
    await props.onPlus();
  }

  /**
   *
   *
   *
   */
  function onOptsWidth() {
    let base = 10;
    if (props.onPlus) base += 18;
    if (props.onUpdate) base += 18;
    if (props.onDelete) base += 18;
    if (isPen || isBin) return 40;
    return base;
  }

  /**
   *
   *
   *
   */
  function getSubProps() {
    return {
      tabIndex: 0,
      onKeyDown: onKeyDownOpts,
      onClick: onSelect,
      $isActive: (props.$isActive && props.showBackColorOnActive),
      $isPlus: $isPlus,
    };
  }

  /**
   *
   *
   *
   */
  function onChange(evt) {
    setNewTitle(evt.target.value);
  }

  /**
   *
   *
   *
   */
  function onSelect(evt) {
    evt.stopPropagation();
    props.onSelect();
  }

  /**
   *
   *
   *
   */
  function onClickPen(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    setIsPen(true);
  }

  /**
   *
   *
   *
   */
  function onClickBin(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    setIsBin(true);
  }

  /**
   *
   *
   *
   */
  function onKeyDownOpts(evt) {
    if (isPen || isBin || !props.$isActive) return;
    if (evt.key === 'Enter') return setIsPen(true);
    if (evt.key === 'Escape') return onClickDismiss(evt);
  }

  /**
   *
   *
   *
   */
  function getStatusIcon() {
    if (isBin) return <I.Bin style={{ stroke: '#FD8C73', transform: 'scale(1.0)' }} />;
    if (isPen) return <I.Pen style={{ stroke: '#FD8C73', transform: 'scale(1.0)' }} />;
    if (props.status === 'DIR')     return <I.Dir />;
    if (props.status === 'SUCCESS') return <I.GreenTick />;
    if (props.status === 'DRAFT')   return <I.Draft style={{transform: 'scale(0.95)'}} />;
    if (props.status === 'WARNING') return <I.WarnTick />;
    return <I.CrossTick />;
  }

  /**
   *
   *
   *
   */
  function onKeyDown(evt) {
    if (evt.key === 'Enter' ) return onUpdate();
    if (evt.key === 'Escape') return onClickDismiss(evt);
  }

  /**
   *
   *
   *
   */
  async function onClickConfirm(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    if (isPen) return onUpdate();
    if (isBin) return onDelete();
  }

  /**
   *
   *
   *
   */
  async function onUpdate() {
    await props.onUpdate(newTitle);
    setIsPen(false);
  }

  /**
   *
   *
   *
   */
  async function onDelete() {
    await props.onDelete();
    setIsBin(false);
  }

  /**
   *
   *
   *
   */
  function onClickDismiss(evt) {
    setNewTitle(props.title);
    evt.stopPropagation();
    setIsPen(false);
    setIsBin(false);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.HoverContainer = styled.div`
  display: none;
  width: 18px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

S.Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.$optsWidth ?? 40}px;
  height: 100%;
  border-radius: 15px;
  cursor: pointer;
  margin-left: 20px;
  svg { color: rgb(180, 185, 190); }
  &:hover svg { color: black; }
`;

S.Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 60px;
  margin-right: 2px;
`;

S.Space = styled.div`
  min-height: ${p => p.$h ?? 20}px;
  min-width: ${p => p.$w ?? 20}px;
`;

S.IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  &:hover path, d { stroke: #1B1B1B; }
`;

S.Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 18px;
`;

S.Input = styled.input`
  font-family: 'Inter';
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  font-style: normal;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #B53306;
  caret-color: #FD8C73;
  width: 100%;
`;

S.TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  height: 100%;
  overflow: clip;
  width: 50%;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`;

S.ISX5 = styled(T.ISX5)`
  margin-top: 2px;
  color: ${p => p.theme.secondary};
`;

S.Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 40px;
  padding: 0px 5px;
  cursor: pointer;
  border-radius: 4px;
  background: ${p => p.$isActive ? 'rgb(246, 248, 250)' : 'transparent'};
  &:hover { background: rgb(246, 248, 250); }
  ${p => p.$isPlus && `
  &:hover {
    ${S.HoverContainer} { display: flex; }
    ${S.Time} { display: none; }
  }
  `}
`;
/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as P from 'react-resizable-panels';
import * as I from './Icons';


/**
 *
 *
 *
 */
export default function ResizePane(props) {
  return (
    <P.PanelResizeHandle>
      {props.$isVerticale ? (
        <S.ResizeContainer $bg={props.$bg}>
          <I.Dots style={{ transform: 'rotate(90deg)' }} />
        </S.ResizeContainer>
      ) : (
        <S.ResizeContainer $bg={props.$bg} style={{ width: '100%' }}>
          <I.Dots />
        </S.ResizeContainer>
      )}
    </P.PanelResizeHandle>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.ResizeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 15px;
  background-color: ${p => p.$bg ?? 'rgb(255, 255, 255)'};
`;
/**
 *
 *
 *
 */
import React from 'react';
import { darken, complement } from 'polished';
import styled from 'styled-components';
import * as I from './Icons';
import Spinner from './Spinner';


/**
 *
 *
 *
 */
ButtonDanger.defaultProps = {
  $bg: ['rgba(255, 0, 0, 0.6)', '#D00'],
  $br: 'rgb(208, 215, 222)',
  $tt: 1000,
  onClick: () => {},
  $style: {},
};


/**
 *
 *
 *
 */
export default function ButtonDanger(props) {

  const [ isWait, setIsWait ] = React.useState(false);
  const [ isConfirm, setIsConfirm ] = React.useState(false);
  const $bg = isConfirm ? props.$bg[1] : props.$bg[0];

  return (
    <S.Container $bg={$bg} $br={props.$br} onClick={onClick} style={props.$style}>
      {onInnerBodyComponent()}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  async function onClick() {
    if (isWait) return;

    if (isConfirm) {
      setIsWait(true);
      await new Promise(r => setTimeout(r, 800));
      await props?.onClick();
      setIsConfirm(false);
      return setIsWait(false);
    }

    setIsWait(true);
    setTimeout(() => {
      setIsConfirm(true);
      setIsWait(false);
    }, props.$tt);
  }

  /**
   *
   *
   *
   */
  function onInnerBodyComponent() {
    if (isWait && isConfirm) return <Spinner $bg={complement(props.$bg[1])} />;
    if (isWait) return <Spinner $bg={complement(props.$bg[0])} />;
    if (isConfirm) return <I.Danger stroke={'white'} style={{transform: 'scale(1.5)'}} />;
    return props?.children;
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${p => p.$bg};
  border: 1px solid ${p => p.$br ?? p.$bg};
  &:hover { cursor: pointer; background: ${p => darken(0.1, p.$bg)}; }
`;

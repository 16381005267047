/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import T from './Typography';
import Spinner from './Spinner';
import * as I from './Icons';
import * as H from '../hooks';
import * as u from '../utils';


/**
 *
 *
 *
 */
Input.defaultProps = {
  onSave: () => {},
  $showLarge: false,
  $popInfo: undefined,
};


/**
 *
 *
 *
 */
export default function Input(props) {

  const currRef = React.useRef(null);
  const [ isWait, setIsWait ] = React.useState(false);
  const [ currTxt, setCurrTxt ] = React.useState(props.value);
  H.useKeyCombo(currRef, onSave);

  return (
    <S.Container style={props.style} $hasCopy={props.$hasCopy}>
      {props.$showLarge && onShowDescription()}
      <S.Input {...onProps()} />
      {onMaybeWaitOrange()}
      {onMaybeCopy()}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onShowDescription() {
    return (
      <S.Description>
        <T.IL4>{props.placeholder + (props.$isRequire ? ' *' : '')}</T.IL4>
        {props.$popInfo && (
          <S.InfoContainer>
            <I.Info />
            <S.PopContainer>
              <S.PopInnerContainer>
                <T.IM4>{props.$popInfo}</T.IM4>
              </S.PopInnerContainer>
            </S.PopContainer>
          </S.InfoContainer>
        )}
      </S.Description>
    );
  }

  /**
   *
   *
   *
   */
  function onMaybeCopy() {
    if (!props.$hasCopy) return;
    return (
      <S.IconContainer>
        <I.Copy onClick={onCopyValue} />
      </S.IconContainer >
    );
  }

  /**
   *
   *
   *
   */
  function onCopyValue() {
    window.navigator.clipboard.writeText(currTxt);
  }

  /**
   *
   *
   *
   */
  function onMaybeWaitOrange() {

    if (isWait) {
      return (
        <S.SpinnerContainer>
          <Spinner $bg={'#FD8C73'} />
        </S.SpinnerContainer>
      );
    }

    if (currTxt !== props.value) {
      return <S.OrangePunto />;
    }

    return null;
  }

  /**
   *
   *
   *
   */
  function onProps() {
    return {
      ref: currRef,
      value: currTxt ?? '',
      onChange: onChange,
      placeholder: props.placeholder,
      readOnly: props.readOnly,
      style: props.innerStyle,
      $hasCopy: props.$hasCopy,
      type: props.$type,
      ...(props.$hasCopy && { onClick: onCopyValue })
    };
  }

  /**
   *
   *
   *
   */
  async function onChange(evt) {
    const nexValue = evt.target.value;
    setCurrTxt(nexValue);
    if (props.onChange) await props.onChange(nexValue);
  }

  /**
   *
   *
   *
   */
  async function onSave() {
    if (isWait) return;
    setIsWait(true);
    await props.onSave(currTxt, props.paramKey);
    setIsWait(false);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${p => p.$hasCopy && `
    &:hover { svg { stroke: #FD8C73; }
  `}
`;

S.Description = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

S.PopInnerContainer = styled.div`
  background: #fff;
  border: 1px solid #FD8C73;
  border-radius: 4px;
  padding: 5px;
  width: 160px;
  height: 60px;
  margin-bottom: 10px;
`;

S.PopContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 10;
`;

S.InfoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  svg { &:hover { cursor: pointer; } }
  ${S.PopContainer} { display: none; }
  &:hover {
    ${S.PopContainer} { display: block; }
    svg { stroke: #FD8C73; }
    #dot { fill: #FD8C73; }
  }
`;

S.Input = styled.input`
  width: 100%;
  min-height: 40px;
  border: 1px solid rgb(208, 215, 222);
  border-radius: 4px;
  background: rgb(246, 248, 250);
  padding: 0px 10px;
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: ${p => p.theme.primary};
  ${p => p.$hasCopy && `
    &:focus { outline: none; }
    &:hover { cursor: pointer; color: #FD8C73; }
    
  `}
`;

S.OrangePunto = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 13px;
  right: 9px;
  background-color: rgb(253, 140, 115);
  border-radius: 50%;
  z-index: 2;
`;

S.SpinnerContainer = styled.div`
  position: absolute;
  right: 1px;
  top: -1px;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.IconContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  &:hover {
    cursor: pointer;
    svg { stroke: #FD8C73; }
  }
`;
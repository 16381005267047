/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';
import * as I from './Icons';
import * as u from '../utils';


/**
 *
 *
 *
 */
const OMNI_SEARCH_USERS = `
  query OmniSearchUsers($query: String!) {
    omni_search_users(query: $query)
  }
`;


/**
 *
 *
 *
 */
OmniSearch.defaultProps = {
  onCurrOutput: res => {},
  setIsWait: isWait => {},
  frg: OMNI_SEARCH_USERS,
  placeholder: 'Search',
};


/**
 *
 *
 *
 */
export default function OmniSearch(props) {

  const currRef = React.useRef('');
  const [ isFocus, setIsFocus ] = React.useState(false);
  const [ currSearch, setCurrSearch ] = React.useState('');
  const [ isWait, setIsWait ] = React.useState(false);
  const debounceFun = React.useCallback(u.debounce(onSearch, 1000), []);
  React.useEffect(onMaybeTrigger, [currSearch, debounceFun]);
  currRef.current = currSearch;
  const isShowClose = isFocus;

  return (
    <S.Container $height={props.$height}>
      <S.InnerContainer>
        <S.SearchIconContainer>
          <I.Search />
        </S.SearchIconContainer>
        <S.Input {...onSearchProps()} />
        {isShowClose && !isWait && (
          <S.CloseIconContainer onClick={onCloseSearch}>
            <I.Close />
          </S.CloseIconContainer>
        )}
        {isWait && (
          <S.SpinnerContainer>
            <Spinner $bg={'#FD8C73'} />
          </S.SpinnerContainer>
        )}
      </S.InnerContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onMaybeTrigger() {
    if (isWait || !currSearch) return;
    setIsWait(true);
    props.setIsWait(true);
    debounceFun(currSearch);
  }

  /**
   *
   *
   *
   */
  async function onSearch(currSearch) {
    const params = { query: currRef.current };
    const [data, err] = await u.fetcher(props.frg, params);
    if (err) return console.log(`onSearch:err`, err);
    props.onCurrOutput(data);
    props.setIsWait(false);
    setIsWait(false);
  }

  /**
   *
   *
   *
   */
  function onSearchProps() {
    return {
      placeholder: props.placeholder,
      value: currSearch,
      onChange: evt => setCurrSearch(evt.target.value),
      onFocus: () => setIsFocus(true),
    };
  }

  /**
   *
   *
   *
   */
  function onCloseSearch() {
    setIsFocus(false);
    setCurrSearch('');
    props.onCurrOutput(undefined);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${p => p.$height ?? 35}px;
  width: 100%;
`;

S.InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
`;

S.SearchIconContainer = styled.div`
  position: absolute;
  height: 100%;
  left: 12px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg { transform: scale(1.1); }
`;

S.CloseIconContainer = styled.div`
  position: absolute;
  height: 100%;
  right: 10px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg { transform: scale(1.2); }
  &:hover { cursor: pointer; svg { stroke: #FDCB6E; } }
`;

S.Input = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid rgb(208, 215, 222);
  border-radius: 4px;
  background: rgb(246, 248, 250);
  padding-left: 35px;
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: ${p => p.theme.primary};
  &:last-child { margin-bottom: 0px; }
`;

S.SpinnerContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 33px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
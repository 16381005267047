/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import Input from '../../_components/Input';
import T from '../../_components/Typography';
import Selector from '../../_components/Selector';
import * as I from '../../_components/Icons';
import Table from '../../_components/Table';
import * as Store from '../store';


/**
 *
 *
 *
 */
const isDev = process.env.NODE_ENV === 'development';
const nex = isDev ? 'http://auth.parvion.localhost:3015' : 'https://auth.parvion.uk';


/**
 *
 *
 *
 */
export default function AccountUsersItem(props) {

  const { actions } = React.useContext(Store.Context);
  const onUpdate = actions.onAdminUpdateUser;
  const id = props?.id;
  const account_id = props?.account_id;

  return (
    <S.Container>
      <S.ColumnContainer>
        <S.InnerContainer>
          <Input $hasCopy={true} readOnly={true} value={props?.id} />
          <S.CreateContainer onClick={() => actions.onAdminCreateDeepLink({ account_id, user_id: id })}>
            <I.Plus style={{ transform: 'scale(1.2)' }} stroke={'#FD8C73'} />
          </S.CreateContainer>
        </S.InnerContainer>
        <Selector {...onStatusProps()} />
        <Input {...onFirstNameProps()} />
        <Input {...onLastNameProps()} />
        <Input {...onEmailProps()} />
        <Input {...onPasswordProps()} />
        <Input {...onAvatarProps()} />
      </S.ColumnContainer>
      <S.ColumnContainer $s={8}>
        <S.PreviewImageContainer>
          <S.Image src={props?.avatar} />
        </S.PreviewImageContainer>
        <Table {...onTableProps()} />
      </S.ColumnContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onFirstNameProps() {
    return {
      placeholder: 'First Name',
      value: props?.first_name,
      onSave: async first_name => await onUpdate({ id, account_id, first_name })
    };
  }

  /**
   *
   *
   *
   */
  function onLastNameProps() {
    return {
      placeholder: 'Last Name',
      value: props?.last_name,
      onSave: async last_name => await onUpdate({ id, account_id, last_name })
    };
  }

  /**
   *
   *
   *
   */
  function onEmailProps() {
    return {
      placeholder: 'Email',
      value: props?.email,
      onSave: async email => await onUpdate({ id, account_id, email })
    };
  }

  /**
   *
   *
   *
   */
  function onPasswordProps() {
    return {
      placeholder: 'Password',
      value: props?.password,
      onSave: async password => await onUpdate({ id, account_id, password })
    };
  }

  /**
   *
   *
   *
   */
  function onStatusProps() {
    return {
      T: T.IL5,
      activeItem: props?.status ?? null,
      onChange: async status => await onUpdate({ id, account_id, status }),
      holder: 'Status',
      opts: ['PENDING', 'ACTIVE', 'INACTIVE'],
    };
  }

  /**
   *
   *
   *
   */
  function onAvatarProps() {
    return {
      placeholder: 'Avatar',
      value: props?.avatar,
      onSave: async avatar => await onUpdate({ id, account_id, avatar })
    };
  }

  /**
   *
   *
   *
   */
  function onTableProps() {
    return {
      subFunc: onSubFunc,
      $theme: '#ebfbee',
      data: props?.deep_links?.map(d => ({
        token: d.token,
        status: d.status,
        created_at: DateTime.fromISO(d.created_at).toFormat('dd-MM-yyyy HH:mm'),
        updated_at: DateTime.fromISO(d.updated_at).toFormat('dd-MM-yyyy HH:mm'),
      })),
    };
  }

  /**
   *
   *
   *
   */
  function onSubFunc(row) {

    const $isLast = R.equals(row.token, R.last(props?.deep_links).token);

    return (
      <S.SubTableContainer $isLast={$isLast}>
        <S.Container>
          <S.ColumnContainer $s={12}>
            <Input {...onTokenProps(row)} />
          </S.ColumnContainer>
        </S.Container>
      </S.SubTableContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onTokenProps(row) {
    return {
      $hasCopy: true,
      readOnly: true,
      placeholder: 'Token',
      value: nex + '?token=' + row?.token,
    };
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
`;

S.ColumnContainer = styled.div`
  height: ${p => p.$h ?? '100%'};
  grid-column: span ${p => p.$s ?? 4};
  display: ${p => p.$d ?? 'grid'};
  grid-row-gap: 10px;
  align-content: start;
`;

S.SubTableContainer = styled.div`
  width: 100%;
  padding: 20px;
  border-top: 1px solid #D0D7DE;
  ${p => !p.$isLast && `border-bottom: 1px solid #D0D7DE;`}
`;

S.CreateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid rgb(208, 215, 222);
  min-width: 50px;
  height: 100%;
  margin-left: 10px;
  &:hover { cursor: pointer; background: rgba(253, 140, 115, 0.1); }
`;

S.InnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

S.PreviewImageContainer = styled.div`
  width: 100%;
  height: 140px;
  background: rgba(34, 139, 230, 0.1);
  border: 1px solid #1864ab;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.Image = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
`;
/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import * as P from 'react-resizable-panels';
import ListaRow from '../../_components/ListaRow';
import ResizePane from '../../_components/ResizePane';
import * as I from '../../_components/Icons';
import Submission from './Submission';
import Summary from './Summary';
import Account from './Account';
import Staff from './Staff';
import Settings from './Settings';
import Species from './Species';
import * as Store from '../store';


/**
 *
 *
 *
 */
const NO_PANE_PATHS = [
  '/',
  '/summary',
  '/submissions',
  '/settings',
  '/species',
];


/**
 *
 *
 *
 */
export default function Home() {

  const nav = Router.useNavigate();
  const { pathname } = Router.useLocation();
  const { state, actions, dispatch } = React.useContext(Store.Context);
  const $isFix = NO_PANE_PATHS.includes(pathname);

  return (
    <S.Container key={pathname}>
      <P.PanelGroup direction={'horizontal'}>
        <P.Panel {...onPaneProps()}>
          {onListaPane()}
        </P.Panel>
        <ResizePane $isVerticale={true} />
        <P.Panel minSize={65}>
          <Router.Routes>
            <Router.Route path='/'            element={<Summary />} />
            <Router.Route path='/summary'     element={<Summary />} />
            <Router.Route path='/submissions' element={<Submission />} />
            <Router.Route path='/accounts'    element={<Account />} />
            <Router.Route path='/staff'       element={<Staff />} />
            <Router.Route path='/settings'    element={<Settings />} />
            <Router.Route path='/species'     element={<Species />} />
          </Router.Routes>
        </P.Panel>
      </P.PanelGroup>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onPaneProps() {
    return {
      defaultSize: $isFix ? 3.55 : 24,
      minSize: $isFix ? 3.55 : 15,
      maxSize: $isFix ? 3.55 : 65,
    };
  }

  /**
   *
   *
   *
   */
  async function onPlus() {
    if (pathname === '/accounts') return await actions.onAdminCreateAccount();
    if (pathname === '/staff') return await actions.onAdminCreateStaff();
  }

  /**
   *
   *
   *
   */
  function onListaPane() {
    return (
      <S.ListaContainer>
        <S.ListaOptionsContainer $isFix={$isFix}>
          <S.IconContainer onClick={onPlus}>
            <I.Plus stroke={'#FD8C73'} />
          </S.IconContainer>
          <S.IconContainer $isActive={pathname === '/summary'} onClick={() => nav('/summary')}>
            <I.Stats stroke="#57606A" strokeWidth="1.0" style={{ transform: 'scale(1.0)' }} />
          </S.IconContainer>
          <S.IconContainer $isActive={pathname === '/staff'} onClick={() => nav('/staff')}>
            <I.Stethoscope stroke="#57606A" strokeWidth="1.0" style={{ transform: 'scale(1.1)', marginLeft: 4 }} />
          </S.IconContainer>
          <S.IconContainer $isActive={pathname === '/accounts'} onClick={() => nav('/accounts')}>
            <I.Briefcase stroke="#57606A" strokeWidth="1.0" style={{ transform: 'scale(1.1)' }} />
          </S.IconContainer>
          <S.IconContainer $isActive={pathname === '/submissions'} onClick={() => nav('/submissions')}>
            <I.Doc stroke="#57606A" strokeWidth="1.0" style={{ transform: 'scale(1.2)' }} />
          </S.IconContainer>
          <S.IconContainer $isActive={pathname === '/species'} onClick={() => nav('/species')}>
            <I.Pet stroke="#57606A" strokeWidth="1.0" style={{ transform: 'scale(1.5)' }} />
          </S.IconContainer>
          <S.IconContainer $isActive={pathname === '/settings'} onClick={() => nav('/settings')} style={{ marginTop: 'auto' }}>
            <I.Settings stroke="#57606A" strokeWidth="1.0" style={{ transform: 'scale(1.1)' }} />
          </S.IconContainer>
        </S.ListaOptionsContainer>
        <S.ListaInnerContainer>
          {pathname === '/accounts' && state?.accounts?.map(getListaItemAccounts)}
          {pathname === '/staff' && state?.staff?.map(getListaItemStaff)}
        </S.ListaInnerContainer>
      </S.ListaContainer>
    );
  }

  /**
   *
   *
   *
   */
  function getListaItemAccounts(elm) {

    const subProps = {
      key: elm.id,
      title: elm.name ?? elm.id,
      subtitle: elm.email ?? '-',
      status: 'DRAFT',
      $isActive: state?.currActiveAccount?.id === Number(elm.id),
      onDelete: async () => await actions.onAdminRemoveAccount({ id: elm.id, status: 'INACTIVE' }),
      onUpdate: async (name) => await actions.onAdminUpdateAccount({ id: elm.id, name }),
      onSelect: () => { dispatch({ type: 'SET_ACTIVE_ACCOUNT', payload: { id: elm.id, type: 'account' } }); },
      time: elm.created_at,
    };

    return <ListaRow {...subProps} />;
  }

  /**
   *
   *
   *
   */
  function getListaItemStaff(elm) {

    const subProps = {
      key: elm.id,
      title: `${elm.first_name ?? '-'} ${elm.last_name ?? '-'}`,
      subtitle: elm.email,
      status: 'DRAFT',
      $isActive: state?.currActiveStaff?.id === Number(elm.id),
      onDelete: async () => await actions.onAdminUpdateStaff({ id: elm.id, status: 'INACTIVE' }),
      onUpdate: async (name) => await actions.onAdminUpdateStaff({ id: elm.id, name }),
      onSelect: () => { dispatch({ type: 'SET_ACTIVE_STAFF', payload: { id: elm.id, type: 'staff' } }); },
      time: elm.created_at,
    };

    return <ListaRow {...subProps} />;
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 80px);
`;

S.ListaContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid rgb(208, 215, 222);
  width: 100%;
  height: 100%;
`;

S.ListaOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${p => p.$isFix ? `
    min-width: 40px;
  ` : `
    min-width: 41px;
    border-right: 1px solid rgb(208, 215, 222);
  `}
`;

S.ListaInnerContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 100%;
`;

S.IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  ${p => p.$isActive && 'background: rgb(236, 238, 250);'}
  &:hover { background: rgb(246, 248, 250); }
`;
/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as I from './Icons';
import T from './Typography';
import * as u from '../utils';


/**
 *
 *
 *
 */
Table.defaultProps = {
  $theme: undefined,
};


/**
 *
 *
 *
 */
export default function Table(props) {

  const isExtra = props.onRemove || props.onShow || props.onSelect;
  const [expRowIdx, setExpRowIdx] = React.useState();
  const cols = React.useMemo(getColsName, [props.data]);
  const rows = React.useMemo(getRows, [props.data]);
  if (rows?.length === 0) return (<T.IL4>Empty</T.IL4>);
  if (!props.data) return;

  return (
    <S.Table cols={cols.length} height={props.height} $gridCols={props.gridCols}>
      <S.TableHead>
        <S.TableRow>
          {cols.map((col, idx) => (
            <S.TableHeadCell $theme={props.$theme} key={`head-${idx}-${col}`}>
              <T.IM7>{col}</T.IM7>
            </S.TableHeadCell>
          ))}
        </S.TableRow>
      </S.TableHead>
      <S.TableBody>
        {rows.map(getRowElm)}
      </S.TableBody>
    </S.Table>
  );

  /**
   *
   *
   *
   */
  function getRowElm(row, idx) {
    return (
      <React.Fragment key={`row-${idx}`}>
        <S.TableRow onClick={onToggle} $hasSubFunc={!!props.subFunc}>
          {isExtra && getActionCol(row)}
          {Object.keys(row).reduce(getCurrRowCol(row), [])}
        </S.TableRow>
        {(expRowIdx === idx) && props.subFunc && (
          <S.ExpRow cols={cols?.length}>
            {props.subFunc(row)}
          </S.ExpRow>
        )}
      </React.Fragment>
    );

    function onToggle(evt) {
      evt.stopPropagation();
      evt.preventDefault();
      if (expRowIdx === idx) setExpRowIdx();
      else setExpRowIdx(idx);
    }
  }

  /**
   *
   *
   *
   */
  function getActionCol(row) {

    const maybeOpts = row['_opts'];

    return (
      <React.Fragment key={'action-column'}>
        <S.ActionCell>
          {props.onRemove && (
            <React.Fragment>
              <I.Bin
                style={{ transform: 'scale(.9)' }}
                onClick={(evt) => onRemove(evt, row)}
                {...(maybeOpts?.textColor ? { stroke: maybeOpts.textColor } : {})}
              />
              <div style={{ width: 8 }} />
            </React.Fragment>
          )}
          {props.onShow && (
            <React.Fragment>
              <I.Open
                style={{ transform: 'scale(1.05) translateY(-0.5px)' }}
                onClick={(evt) => onShow(evt, row)}
                {...(maybeOpts?.textColor ? { stroke: maybeOpts.textColor } : {})}
              />
              <div style={{ width: 8 }} />
            </React.Fragment>
          )}
          {props.onSelect && (
            <React.Fragment>
              <I.FigmaPlus
                style={{ transform: 'scale(1.3) translateY(-0.5px)' }}
                onClick={(evt) => { evt.stopPropagation(); onSelect(evt, row); }}
                {...(maybeOpts?.textColor ? { stroke: maybeOpts.textColor } : {})}
              />
              <div style={{ width: 8 }} />
            </React.Fragment>
          )}
        </S.ActionCell>
      </React.Fragment>
    );
  }

  /**
   *
   *
   *
   */
  function getCurrRowCol(row) {
    const maybeOpts = row['_opts'];
    return (acc, rowKey, index) => {
      if (rowKey === '_opts') return acc;
      return acc.concat(
        <S.TableCell key={index}>
          <T.IM4 style={{color: maybeOpts?.textColor}}>
            {u.trunc(getValue(row[rowKey]), 500)}
          </T.IM4>
        </S.TableCell>
      );
    };
  }

  /**
   *
   *
   *
   */
  function getColsName() {
    if (props.data?.length === 0) return [];
    if (props.data?.length > 0 && isExtra) return ['', ...Object.keys(props.data[0]).filter(key => key !== '_opts')];
    if (props.data?.length > 0) return Object.keys(props.data[0]).filter(key => key !== '_opts');
    return [];
  }

  /**
   *
   *
   *
   */
  function getRows() {
    return props.data || [];
  }

  /**
   *
   *
   *
   */
  function getValue(elmValue) {
    if (typeof elmValue === 'object') return JSON.stringify(elmValue, null, 2);
    return elmValue;
  }

  /**
   *
   *
   *
   */
  function onRemove(evt, row) {
    evt.stopPropagation();
    evt.preventDefault();
    props.onRemove(row);
  }

  /**
   *
   *
   *
   */
  function onShow(evt, row) {
    evt.stopPropagation();
    evt.preventDefault();
    props.onShow(row);
  }

  /**
   *
   *
   *
   */
  function onSelect(evt, row) {
    evt.stopPropagation();
    evt.preventDefault();
    props.onSelect(row);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Table = styled.div`
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns: ${p => p.$gridCols || `repeat(${p.cols}, 1fr)`};
  border: 1px solid #D0D7DE;
  border-radius: 4px;
  overflow: scroll;
  ${p => p.height
    ? `max-height: ${p.height}px;`
    : `max-height: 100%;`
  }
`;

S.TableHead = styled.div`
  display: contents;
  border-bottom: 1px solid #D0D7DE;
`;

S.TableHeadCell = styled.div`
  top: 0;
  display: flex;
  align-items: center;
  padding: 0px 6px;
  height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: sticky;
  z-index: 1;
  text-align: left;
  color: white;
  border-left: 1px solid #D0D7DE;
  border-bottom: 1px solid #D0D7DE;
  background-color: ${p => p.$theme ?? '#F6F8FA'};
  &:first-child { border-left: 0px solid #D0D7DE }
`;

S.TableBody = styled.div`
  display: contents;
  border-top: 1px solid #D0D7DE;
`;

S.TableRow = styled.div`
  display: contents;
  ${p => p.$hasSubFunc && `&:hover { cursor: pointer; }`}
  &:nth-child(even) div { background: #F6F8FA; }
`;

S.ExpRow = styled.div`
  grid-column: span ${p => p.cols};
`;

S.TableCell = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 6px;
  height: 30px;
  color: #808080;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: 1px solid #D0D7DE;
  &:first-child { border-left: 0px solid #D0D7DE; }
`;

S.ActionCell = styled.div`
  display: flex;
  align-items: center;
  left: 0;
  padding: 4px;
  background-color: inherit;
  svg:hover { cursor: pointer; stroke: #FD8C73; }
`;

/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import T from './Typography';
import MonacoEditor from './MonacoEditor';
import MarkdownPreview from './MarkdownPreview';


/**
 *
 *
 *
 */
GitHubLikeEditor.defaultProps = {
  onSave: () => {},
  value: 'default-prop-value',
  id: 'default-prop-id',
  showPreview: false,
};


/**
 *
 *
 *
 */
export default function GitHubLikeEditor(props) {

  const [ isWrite, setIsWrite ] = React.useState(props.isWrite ?? true);
  const isPreview = !isWrite;

  return (
    <S.Container>
      <S.Header>
        <S.Option $isActive={isWrite} onClick={onWrite}>
          <T.IL4>Write</T.IL4>
        </S.Option>
        {props.showPreview && (
          <S.Option $isActive={isPreview} onClick={onPreview}>
            <T.IL4>Preview</T.IL4>
          </S.Option>
        )}
      </S.Header>
      <S.InnerContainer>
        <S.MaybeDisplayNone $isDisplay={isWrite}>
          <MonacoEditor
            value={props.value}
            onSave={props.onSave}
            onChange={props.onChange}
            onSubmit={props.onSubmit}
            readOnly={props.readOnly}
            saveOnKeyStroke={props.saveOnKeyStroke}
            rulers={props.rulers}
            info={props.info}
            font={props.font}
            id={props.id}
          />
        </S.MaybeDisplayNone>
        <S.MaybeDisplayNone $isDisplay={isPreview}>
          <S.MarkdownContainer>
            <MarkdownPreview value={props.value} />
          </S.MarkdownContainer>
        </S.MaybeDisplayNone>
      </S.InnerContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onWrite() {
    setIsWrite(true);
  }

  /**
   *
   *
   *
   */
  function onPreview() {
    setIsWrite(false);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  border: 1px solid rgba(208, 215, 222, 1);
  border-radius: 6px;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

S.Header = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid rgba(208, 215, 222, 1);
  border-radius: 6px 6px 0px 0px;
  background: rgba(246, 248, 250, 1);
  padding: 9px;
`;

S.Option = styled.div`
  height: 39px;
  padding: 8px 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${p => p.$isActive ? `
    background: white;
    border: 1px solid rgba(208, 215, 222, 1);
    border-bottom: 1px solid white;
    ${T.ILA} { color: rgba(36, 41, 47, 1); }
  ` : `
    background: rgba(246, 248, 250, 1);
    border: 1px solid rgba(246, 248, 250, 1);
    border-bottom: 1px solid rgba(208, 215, 222, 1);
    ${T.ILA} { color: rgba(87, 96, 106, 1); }
  `}
  &:hover {
    cursor: pointer;
    ${T.ILA} { color: rgba(36, 41, 47, 1); }
  }
`;

S.InnerContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 48px;
  bottom: 0px;
  padding: 10px;
  .markdown-body {
    padding-top: 1em;
    padding-bottom: 1em;
  }
`;

S.MaybeDisplayNone = styled.div`
  height: 100%;
  ${p => p.$isDisplay ? `` : `
    display: none;
  `}
`;

S.MarkdownContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 0px 15px;
`;
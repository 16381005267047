/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import Spinner from './Spinner';
import T from './Typography';
import * as I from './Icons';
import * as H from '../hooks';


/**
 *
 *
 *
 */
Selector.defaultProps = {
  onChange: () => { console.warn('Selector: no onChange prop'); },
  unit: undefined,
  $showLarge: false,
  $popInfo: undefined,
};


/**
 *
 *
 *
 */
export default function Selector(props) {

  const currRef = React.useRef();
  const otherRef = React.useRef();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  H.useOnClickOutside([currRef, otherRef], () => setIsOpen(false));
  const value = onShowValue();
  const isHolder = !props.activeItem;
  const TT = props.T ?? T.IS5;
  const unit = props.unit ? ` ${props.unit}` : '';

  return (
    <S.SuperContainer>
      {props.$showLarge && onShowDescription()}
      <S.Container $height={props.$height} $width={props.$width} onClick={onClick}>
        <S.SpinnerContainer>
          {isLoading && <Spinner $bg={'#FD8C73'} />}
        </S.SpinnerContainer>
        <S.InnerContainer ref={otherRef}>
          <TT color={isHolder ? '#D0D7DE' : undefined}>
            {value + unit}
          </TT>
        </S.InnerContainer>
        <S.OptionsContainer $isOpen={isOpen} ref={currRef}>
          {getOption(props.holder, -1)}
          {props.opts.map(getOption)}
        </S.OptionsContainer>
      </S.Container>
    </S.SuperContainer>
  );

  /**
   *
   *
   *
   */
  function onShowDescription() {
    return (
      <S.Description>
        <T.IL4>{props.holder + (props.$isRequire ? ' *' : '')}</T.IL4>
        {props.$popInfo && (
          <S.InfoContainer>
            <I.Info />
            <S.PopContainer>
              <S.PopInnerContainer>
                <T.IM4>{props.$popInfo}</T.IM4>
              </S.PopInnerContainer>
            </S.PopContainer>
          </S.InfoContainer>
        )}
      </S.Description>
    );
  }

  /**
   *
   *
   *
   */
  function onClick(evt) {
    evt.stopPropagation();
    setIsOpen(!isOpen);
  }

  /**
   *
   *
   *
   */
  function getOption(elm, idx) {

    const subProps = {
      $isActive: isMaybeActive(),
      onClick: onSubClick,
      $height: props.$height,
      $isReset: idx === -1,
    };

    return (
      <S.OptionsItem key={`${idx}-${elm}`} {...subProps}>
        <TT>{elm}</TT>
      </S.OptionsItem>
    );

    async function onSubClick(evt) {
      evt.stopPropagation();
      evt.preventDefault();
      setIsOpen(false);
      setIsLoading(true);
      const xor = R.converge(R.difference, [R.union, R.intersection]);
      const nex = props.isMultiple ? xor(props.activeItem, [elm]) : elm;
      const param = subProps.$isReset ? '' : nex;
      await props.onChange(param);
      setIsLoading(false);
    }

    function isMaybeActive() {
      if (props.isMultiple) return props.activeItem.includes(elm);
      return elm === value;
    }
  }

  /**
   *
   *
   *
   */
  function onShowValue() {
    if (props.isMultiple) {
      if (R.isEmpty(props.activeItem)) return props.holder;
      return props.activeItem.join(', ');
    }
    return props.activeItem || props.holder;
  }
}


/**
 *
 *
 *
 */
const S = {};

S.SuperContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

S.Description = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

S.PopInnerContainer = styled.div`
  background: #fff;
  border: 1px solid #FD8C73;
  border-radius: 4px;
  padding: 5px;
  width: 160px;
  height: 60px;
  margin-bottom: 10px;
`;

S.PopContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 10;
`;

S.InfoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  svg { &:hover { cursor: pointer; } }
  ${S.PopContainer} { display: none; }
  &:hover {
    ${S.PopContainer} { display: block; }
    svg { stroke: #FD8C73; }
    #dot { fill: #FD8C73; }
  }
`;

S.Container = styled.div`
  position: relative;
  width: ${p => p.$width ?? '100%'};
  display: flex;
  align-items: center;
  min-height: ${p => p.$height ?? 40}px;
  border-radius: 4px;
  padding: 0px 10px;
  &:hover { cursor: pointer; }
  border: 1px solid rgb(208, 215, 222);
`;

S.InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p { font-weight: 500; }
  svg { color: rgba(208, 215, 222, 1); }
`;

S.OptionsContainer = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: -1px;
  visibility: hidden;
  opacity: 0;
  background-color: white;
  border: 1px solid rgba(208, 215, 222, 1);
  border-radius: 4px;
  width: calc(100% + 2px);
  z-index: 10;
  ${p => p.$isOpen ? `
    visibility: visible;
    opacity: 1;
  ` : ``}
`;

S.OptionsItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${p => p.$height ?? 40}px;
  border-radius: 4px;
  padding: 0px 8px;
  background-color: white;
  &:hover { background: rgb(246, 248, 250); }
  &:hover { cursor: pointer; }
  ${p => p.$isActive && `background: rgb(246, 248, 250);`}
  ${p => p.$isReset && `opacity: 0.4;`}
  &:first-child { p { font-weight: 400; } }
`;

S.SpinnerContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
`;
/**
 *
 *
 *
 */
import React from 'react';
import * as d3 from 'd3';
import { useTheme } from 'styled-components';


/**
 *
 *
 *
 */
export const useTypeWriter = (text, speed = 1000) => {

  const [ txt, setTxt ] = React.useState('');
  React.useEffect(onStart, [txt, speed]);
  return txt;

  /**
   *
   *
   *
   */
  function onStart() {
    const id = setTimeout(onTick, speed);
    return () => clearTimeout(id);
  }

  /**
   *
   *
   *
   */
  function onTick() {
    if (txt.length === text.length) return;
    const nex = Math.min(txt.length + 6, text.length);
    setTxt(text.slice(0, nex));
  }
};


/**
 *
 *
 *
 */
export const useOnClickOutside = (refs, handler) => {

  React.useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (refs.some(ref => ref.current && ref.current.contains(event.target))) return;
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new
    // function on every render that will cause this effect
    // callback/cleanup to run every render. It's not a big deal
    // but to optimize you can wrap handler in useCallback before
    // passing it into this hook.
    [refs, handler]
  );
};


/**
 *
 *
 *
 */
export const useWheel = (srcRef, trgRef) => {

  React.useEffect(onAttach, [srcRef, trgRef]);

  /**
   *
   *
   *
   */
  function onAttach() {
    if (!srcRef.current || !trgRef.current) return;
    srcRef.current.addEventListener('wheel', onWheel, { passive: false });
    return () => { srcRef.current?.removeEventListener('wheel', onWheel, { passive: false }); };
  }

  /**
   *
   *
   *
   */
  function onWheel(evt) {
    evt.preventDefault();

    const curElm = trgRef.current.style.transform;
    const matchT = curElm.match(/translate3d\(\s*([^,]+),\s*([^,]+),\s*[^)]+\)/);
    const matchS = curElm.match(/scale\(([^)]+)\)/);

    let currX = matchT ? parseFloat(matchT[1]) : 0;
    let currY = matchT ? parseFloat(matchT[2]) : 0;
    let currS = matchS ? parseFloat(matchS[1]) : 1;

    const newX = currX - evt.deltaX;
    const newY = currY - evt.deltaY;
    const newS = Math.min(Math.max(currS + (-evt.deltaY * 0.005), 0.4), 3);

    if (evt.ctrlKey || evt.metaKey) return trgRef.current.style.transform = `translate3d(${currX}px, ${currY}px, 0px) scale(${newS})`;
    trgRef.current.style.transform = `translate3d(${newX}px, ${newY}px, 0px) scale(${currS})`;
  }
};


/**
 *
 *
 *
 */
export const useDragMove = (srcRef, trgRef) => {

  const isDrag = React.useRef(false);
  const iniPts = React.useRef({ x: 0, y: 0 });

  /**
   *
   *
   *
   */
  React.useEffect(() => {
    if (!srcRef.current || !trgRef.current) return;
    const elm = srcRef.current;
    elm.addEventListener('mousedown', onMouseDown);
    elm.addEventListener('mousemove', onMouseMove);
    elm.addEventListener('mouseup',   onMouseUp);

    return () => {
      elm.removeEventListener('mousedown', onMouseDown);
      elm.removeEventListener('mousemove', onMouseMove);
      elm.removeEventListener('mouseup',   onMouseUp);
    };
  }, [srcRef, trgRef]);

  /**
   *
   *
   *
   */
  function onMouseDown(evt) {
    isDrag.current = true;
    srcRef.current.style.cursor = 'move';
    iniPts.current = { x: evt.clientX, y: evt.clientY };
  }

  /**
   *
   *
   *
   */
  function onMouseMove(evt) {

    if (!isDrag.current) return;
    const deltaX = evt.clientX - iniPts.current.x;
    const deltaY = evt.clientY - iniPts.current.y;
    iniPts.current = { x: evt.clientX, y: evt.clientY };

    const curElm = trgRef.current.style.transform;
    const matchT = curElm.match(/translate3d\(\s*([^,]+),\s*([^,]+),\s*[^)]+\)/);
    const matchS = curElm.match(/scale\(([^)]+)\)/);

    let currX = matchT ? parseFloat(matchT[1]) : 0;
    let currY = matchT ? parseFloat(matchT[2]) : 0;
    let currS = matchS ? parseFloat(matchS[1]) : 1;

    const newX = currX + deltaX;
    const newY = currY + deltaY;

    trgRef.current.style.transform = `translate3d(${newX}px, ${newY}px, 0px) scale(${currS})`;
    trgRef.current.style.userSelect = 'none';
    trgRef.current.style.pointerEvents = 'none';
  }

  /**
   *
   *
   *
   */
  function onMouseUp() {
    isDrag.current = false;
    srcRef.current.style.cursor = 'auto';
    trgRef.current.style.userSelect = '';
    trgRef.current.style.pointerEvents = '';
  }
};


/**
 *
 *
 *
 */
export const useGraph = (ref, opts, points) => {

  const { W, H, M } = opts;
  const t = useTheme();
  React.useEffect(onInitialise, [ref, points]);

  /**
   *
   *
   *
   */
  function onInitialise() {

    if (!ref.current || !points) return;
    const xDomain = d3.extent(points, d => d.time);
    const xRange = [M.L, W - M.R];
    const yRange = [H - M.B, M.T];

    const axisColor = 'rgba(87, 96, 106, 1)';
    const x = d3.scaleUtc().domain(xDomain).range(xRange);
    const y = d3.scaleLinear([0, 1]).rangeRound(yRange);

    const area = d3.area()
      .x(d => x(d.time))
      .y0(d => y(0))
      .y1(d => y(d.value));

    const svg = d3.select(ref.current)
      .attr('width', W)
      .attr('height', H)
      .attr('viewBox', [0, 0, W, H])
      .attr('style', 'max-width: 100%; height: auto;');

    svg.append('path')
      .attr('fill', 'rgba(048, 199, 088, 0.20)')
      .attr('d', area(points.filter(d => d.type === 'power')));

    svg.append('path')
      .attr('fill', 'rgba(048, 199, 088, 0.85)')
      .attr('d', area(points.filter(d => d.type === 'utils')));

    svg.append('g')
      .attr('transform', `translate(0, ${H - M.B})`)
      .attr('color', axisColor)
      .call(d3.axisBottom(x).tickSizeOuter(0))
      .call(g => g.select('.domain').remove());

    svg.append('g')
      .attr('transform', `translate(${W - M.R}, 0)`)
      .attr('color', axisColor)
      .call(d3.axisRight(y).ticks(H / 80, '%'))
      .call(g => g.select('.domain').remove());

    svg.append('g')
      .attr('transform', `translate(${M.L}, 0)`)
      .attr('color', axisColor)
      .call(d3.axisLeft(y).ticks(H / 80, '%'))
      .call(g => g.select('.domain').remove())
      .call(g => {
        return g.selectAll('.tick line')
          .clone()
          .attr('x2', W - (2 * M.R))
          .attr('stroke-opacity', 0.1);
      });

    return () => { svg.selectAll('*').remove(); };
  }
};


/**
 *
 *
 *
 */
export const useKeyCombo = (ref, handler) => {

  React.useEffect(onAttach, [ref, handler]);

  /**
   *
   *
   *
   */
  function onAttach() {

    if (!ref.current) return;
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);

    /**
     *
     *
     *
     */
    function onKeyDown(evt) {
      if ((evt.metaKey || evt.ctrlKey) && evt.key === 's' && ref.current.contains(document.activeElement)) {
        evt.preventDefault();
        handler();
      }
    };
  }
};

/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import GitHubLikeEditor from '../../_components/GitHubLikeEditor';
import ButtonWait from '../../_components/Button.Wait';
import T from '../../_components/Typography';
import Input from '../../_components/Input';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function StaffReports() {

  const { state, actions } = React.useContext(Store.Context);
  const currItem = onActiveItemInfo();
  const id = currItem?.id;

  return (
    <S.Container></S.Container>
  );

  /**
   *
   *
   *
   */
  function onActiveItemInfo() {
    if (!state?.currActiveStaff?.id) return;
    const isSame = e => Number(e.id) === state?.currActiveStaff?.id;
    return state?.staff?.find(isSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div``;
/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import OptionsTiny from '../../_components/Options.Tiny';
import AccountOverview from '../components/Account.Overview';
import AccountUsers from '../components/Account.Users';
import * as I from '../../_components/Icons';
import * as Store from '../store';


/**
 *
 *
 *
 */
const OPTS = [
  { label: 'Overview',    icon: I.Store },
  { label: 'Users',       icon: p => <I.Profile style={{ transform: 'scale(1.2)' }} {...p} /> },
  { label: 'Reports',     icon: p => <I.Doc style={{ transform: 'scale(1.2)' }} {...p} /> },
];


/**
 *
 *
 *
 */
export default function Account() {

  const [ currTab, setCurrTab ] = React.useState(0);
  const { state, actions } = React.useContext(Store.Context);
  const currItem = onActiveItemInfo();
  if (!currItem) return null;

  return (
    <S.Container>
      <OptionsTiny {...onOptsProps()} />
      {getBodiesComponent()}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onOptsProps() {
    return {
      opts: OPTS,
      onSet: setCurrTab,
      idx: currTab,
      onRefresh: actions.onGetCompanies,
    };
  }

  /**
   *
   *
   *
   */
  function getBodiesComponent() {
    return (
      <React.Fragment>
        <S.MaybeDisplayNone $isDisplay={(currTab === 0)}>
          <AccountOverview key={currItem?.id} />
        </S.MaybeDisplayNone>
        <S.MaybeDisplayNone $isDisplay={(currTab === 1)}>
          <AccountUsers key={currItem?.id} />
        </S.MaybeDisplayNone>
      </React.Fragment>
    );
  }

  /**
   *
   *
   *
   */
  function onActiveItemInfo() {
    if (!state?.currActiveAccount?.id) return;
    const isSame = e => Number(e.id) === state?.currActiveAccount?.id;
    return state?.accounts?.find(isSame);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

S.MaybeDisplayNone = styled.div`
  height: 100%;
  ${p => p.$isDisplay || `display: none;`}
`;

/**
 *
 *
 *
 */
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import * as I from  './Icons';
import T from './Typography';


/**
 *
 *
 *
 */
export default function OptionsTiny(props) {

  const [ isRefresh, setIsRefresh ] = React.useState(false);

  return (
    <S.Container>
      {props.opts.map(onItem)}
      {props.onRefresh && onRefreshComponent()}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onItem(elm, idx) {

    const Icon = elm.icon ?? (() => null);
    const $color = props.idx === idx ? '#FD8C73' : '';
    const params = onMaybeSubProps(elm, idx);

    return (
      <S.SectionContainer onClick={() => props.onSet(idx)} key={idx}>
        <S.IconContainer>
          <Icon {...params} />
        </S.IconContainer>
        <T.IM5 color={$color}>
          {elm.label}
        </T.IM5>
      </S.SectionContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onMaybeSubProps(elm, idx) {
    const params = { stroke: '#8B949E' };
    if (elm.scale) params.style = { transform: `scale(${elm.scale})` };
    if (props.idx === idx) params.stroke = '#FD8C73';
    return params;
  }

  /**
   *
   *
   *
   */
  function onRefreshComponent() {
    return (
      <S.IconContainer $isRefresh={isRefresh} style={{ marginLeft: 'auto' }} onClick={onRefresh}>
        <I.FigmaRefresh style={{ transform: 'scale(1.3)' }} />
      </S.IconContainer>
    );
  }

  /**
   *
   *
   *
   */
  async function onRefresh() {
    if (isRefresh) return;
    setIsRefresh(true);
    await props.onRefresh();
    setIsRefresh(false);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 40px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid rgb(208, 215, 222);
  background: rgb(246, 248, 250);
`;

S.SectionContainer = styled.div`
  height: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  padding-right: 20px;
  border-right: 1px solid rgb(208, 215, 222);
  &:hover {
    cursor: pointer;
    p { color: black; }
    svg { stroke: black; }
  }
`;

S.IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: rgb(246, 248, 250);
    div { background: rgb(246, 248, 250); }
    svg { stroke: black; }
  }
  ${p => p.$isRefresh ? css`
    animation: ${keyframes`
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    `} 1s linear infinite;
    svg { stroke: #FD8C73 !important; }
  ` : ``}
`;
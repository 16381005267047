/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import { Marked } from 'marked';
import markedKatex from 'marked-katex-extension';
import { markedHighlight } from 'marked-highlight';
import hljs from 'highlight.js';


/**
 *
 *
 *
 */
import 'highlight.js/styles/github.css';
import 'github-markdown-css/github-markdown.css';
import 'katex/dist/katex.min.css';


/**
 *
 *
 *
 */
const marked = new Marked(
  markedHighlight({
    langPrefix: 'hljs language-',
    highlight(code, lang) {
      return hljs.highlightAuto(code, [lang]).value;
    }
  })
);


/**
 *
 *
 *
 */
marked.use(markedKatex({ throwOnError: false }));
marked.use({gfm: true});


/**
 *
 *
 *
 */
export default function CreatePreview(props) {

  const html = React.useMemo(() => marked.parse(props.value ?? ''), [props.value]);
  return (
    <S.Container
      className="markdown-body"
      dangerouslySetInnerHTML={{ __html: html }}
      $bg={props.$bg}
    />
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  &.markdown-body {
    font-size: 14px;
    color: rgba(36, 41, 47, 1);
    background: ${p => p.$bg ?? '#fff'};
    --color-background: #fff;
    --color-text: rgba(36, 41, 47, 1);
    --color-canvas-default: #fff;
    --color-canvas-subtle: #f3f4f6;
  }
`;
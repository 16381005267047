/**
 *
 *
 *
 */
export const AUTH_GET_ME = `
  query AuthGetMe {
    auth_get_me
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_GET_ACCOUNTS = `
  query AdminGetAccounts {
    admin_get_accounts
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_CREATE_ACCOUNT = `
  mutation AdminCreateAccount {
    admin_create_account
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_UPDATE_ACCOUNT = `
  mutation AdminUpdateAccount(
    $id: ID!,
    $name: String,
    $address: String,
    $phone: String,
    $website: String,
    $email: String,
    $password: String,
    $tax_id: String,
    $notes: String
  ) {
    admin_update_account(
      id: $id,
      name: $name,
      address: $address,
      phone: $phone,
      website: $website,
      email: $email,
      password: $password,
      tax_id: $tax_id,
      notes: $notes
    )
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_REMOVE_ACCOUNT = `
  mutation AdminRemoveAccount {
    admin_remove_account
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_CREATE_SUBMISSIONS = `
  mutation AdminCreateSubmissions {
    admin_create_submissions
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_GET_SUBMISSIONS = `
  query AdminGetSubmissions {
    admin_get_submissions
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_CREATE_USER = `
  mutation AdminCreateUser($account_id: ID!) {
    admin_create_user(account_id: $account_id)
  }
`;

/**
 *
 *
 *
 */
export const ADMIN_UPDATE_USER = `
  mutation AdminUpdateUser(
    $id: ID!,
    $account_id: ID!,
    $first_name: String,
    $last_name: String,
    $email: String,
    $password: String,
    $phone: String,
    $avatar: String,
    $role: String,
    $status: String
  ) {
    admin_update_user(
      id: $id,
      account_id: $account_id,
      first_name: $first_name,
      last_name: $last_name,
      email: $email,
      password: $password,
      phone: $phone,
      avatar: $avatar,
      role: $role,
      status: $status
    )
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_GET_STAFF = `
  query AdminGetStaff {
    admin_get_staff
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_CREATE_STAFF = `
  mutation AdminCreateStaff {
    admin_create_staff
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_UPDATE_STAFF = `
  mutation AdminUpdateStaff(
    $id: ID!,
    $first_name: String,
    $last_name: String,
    $email: String,
    $password: String,
    $phone: String,
    $avatar: String,
    $role: String,
    $status: String
  ) {
    admin_update_staff(
      id: $id,
      first_name: $first_name,
      last_name: $last_name,
      email: $email,
      password: $password,
      phone: $phone,
      avatar: $avatar,
      role: $role,
      status: $status
    )
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_GET_USERS = `
  query AdminGetUsers {
    admin_get_users
  }
`;


/**
 *
 *
 *
 */
export const AUTH_GET_REDIS = `
  query AuthGetRedis($key: String!) {
    auth_get_redis(key: $key)
  }
`;


/**
 *
 *
 *
 */
export const AUTH_SET_REDIS = `
  mutation AuthSetRedis($key: String!, $value: String!, $ttl: Int) {
    auth_set_redis(key: $key, value: $value, ttl: $ttl)
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_GET_SPECIES = `
  query AppGetSpecies {
    app_get_species
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_CREATE_SPECIE = `
  mutation AdminCreateSpecie {
    admin_create_specie
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_UPDATE_SPECIE = `
  mutation AdminUpdateSpecie(
    $id: ID!,
    $name: String
  ) {
    admin_update_specie(
      id: $id,
      name: $name
    )
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_CREATE_BREED = `
  mutation AdminCreateBreed($specie_id: ID!) {
    admin_create_breed(specie_id: $specie_id)
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_UPDATE_BREED = `
  mutation AdminUpdateBreed(
    $id: ID!,
    $name: String
  ) {
    admin_update_breed(
      id: $id,
      name: $name
    )
  }
`;


/**
 *
 *
 *
 */
export const ADMIN_CREATE_DEEP_LINK = `
  mutation AdminCreateDeepLink($user_id: ID!) {
    admin_create_deep_link(user_id: $user_id)
  }
`;
